import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const PandemicInformation = makeShortcode("PandemicInformation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "emotions-during-the-pandemic"
    }}>{`Emotions during the pandemic`}</h2>
    <p><strong parentName="p">{`Have you noticed your teen is more emotional during the pandemic?`}</strong></p>
    <SingleImage src="/images/m1/emotion-words.png" alt="Emotive words" mdxType="SingleImage" />
    <p>{`All of the above emotions are normal and expected. Teenagers are likely to feel a range of strong emotions during this time. Your teen’s emotional response will depend on their age, maturity, their understanding of the pandemic, the response of adults around them (including you and other family members), their personality, and their coping skills. Let your teen know that it’s normal and okay to feel these emotions.`}</p>
    <p>{`Some teens might express their emotions through challenging behaviours, for example defiance or acting out. If you’ve found your teen’s behaviour has changed during the pandemic, keep in mind that this could be their way of expressing strong emotions. Remember, your teen is trying to navigate all of the challenges of the pandemic, while also developing the ability to express and cope with their emotions.`}</p>
    <PandemicInformation mdxType="PandemicInformation">
  The tips outlined throughout this module are applicable all of the time, even beyond the pandemic.
    </PandemicInformation>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      